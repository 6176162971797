@import './src/scss/variables';
@import './src/scss/game';

$colors: #fe465d, #3fc9c9, #e76d45, #538aed, #f0d13a, #b673e6, #57b12c;

@for $i from 1 through length($colors) {
  .color#{$i}, .color#{$i}-remove-active {
    background: nth($colors, $i)
  }
}

.grid {
  //border: 1px solid black;
  display: inline-flex;
  flex-direction: column;
  left: 50%;
  //position: absolute;
  top: 50%;
  //transform: translate(-50%, -50%);
  margin: 0 auto;
  height: 60vmin;
  width: 60vmin;
  //background: rgb(242, 243, 245);

	&__row {
	  display: flex;
	  flex-direction: row;
	  height: 100%;
	  position: relative;
	  width: 100%;
	  background: rgb(242, 243, 245);
//
// 	  &:first-child {
// 	    border-top-left-radius: 20px;
// 	    border-top-right-radius: 20px;
// 	  }
//
// 	  &:last-child {
// 	    border-bottom-left-radius: 20px;
// 	    border-bottom-right-radius: 20px;
// 	  }
	}

	&__tile {
	  border-radius: 25%;
	  //position: relative;
	  width: 100%;
	  background-color: white;
	  margin: 7px;

	  display: flex;
	  justify-content: center;
	  align-items: center;

	  &:has(.ball.selected) {
	    background-color: darkgray;
	  }

		&_even {
		  //background-image: linear-gradient(45deg, rgba(2, 204, 255, 0.3), white);
		}

		&_odd {
		  //background-image: linear-gradient(45deg, white, rgba(2, 204, 255, 0.3));
		}
	}

	&_next {
    height: 20vmin;
    width: calc(60vmin / 9);
	}
}

.empty {
  width: 100%;
  height: 100%;

  &.empty-add {
    border-radius: 100%;
    width: 80%;
    height: 80%;
    animation: crescendo-reverse 0.1s alternate ease-in;
  }
}

.hint, .hint-remove {
  border-radius: 100%;
  border: 9px solid white;
  width: 80% !important;
  height: 80% !important;

  &.empty-add {
    animation: crescendo-reverse 0.1s alternate ease-in !important;
  }
}

.ball {
  border-radius: 100%;
  cursor: pointer;
  width: 80%;
  height: 80%;

  &.ball-add {
    animation: crescendo 0.1s alternate ease-in;
  }

  &:hover {
      width: 85%;
      height: 85%;
  }
}

@keyframes crescendo {
  0% {
    transform: scale(.5);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes crescendo-reverse {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}